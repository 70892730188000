import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './Home.js'
import Store from './Store.js'
import Support from './Support.js'
import PrivacyPolicy from './PrivacyPolicy.js'
import TermsAndConditions from './TermsAndConditions.js'
import Play from './Play.js'
import PlayFrogGame from './PlayFrogGame.js'
import PlayHogGame from './PlayHogGame.js'
import PlayDogGame from './PlayDogGame.js'


const theme = createTheme({
  palette: {
    background: {
      default: "#222034"
    },
    primary: {
      main: '#ff6900'
    },
    secondary: {
      main: '#222034'
    },
    text: {
      primary: '#222034',
      secondary: '#ff6900'
    }
  }
});

export default function PaulSoft() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none', backgroundColor: "#ff6900" } }} />
      <CssBaseline />
      <Router>
        {/* <Home />
        <Store /> */}
        {/* End footer */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/store" element={<Store />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/playfroggame" element={<PlayFrogGame />} />
          <Route path="/playfroggame.html" element={<PlayFrogGame />} />
          <Route path="/play" element={<Play />} />
          <Route path="/play.html" element={<Play />} />
          <Route path="/playhoggame" element={<PlayHogGame />} />
          <Route path="/playhoggame.html" element={<PlayHogGame />} />
          <Route path="/playdoggame" element={<PlayDogGame />} />
          <Route path="/playdoggame.html" element={<PlayDogGame />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
