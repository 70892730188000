// import React from 'react'
import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Footer from './components/Footer.js'
import Header from './components/Header.js'


const Support = () => {
  return (
    <div>
      <Header />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          bgcolor: '#9faee9'
        }}
      >
        <Box
          component="img"
          alt="PaulSoft Games Banner Image"
          src="./banner3.png"
          width="100%"
        />
      </Grid>
      <Box
        sx={{
          bgcolor: '#222034',
          py: 2,
        }}
      >
        <Container maxWidth="sm">
          {/* <Typography
            component="h3"
            variant="h4"
            align="center"
            color="text.secondary"
            gutterBottom
          >
          </Typography> */}
          <Typography variant="h6" align="center" color="text.secondary" paragraph>
            <h1>Support</h1>
            {/* <p>PaulSoft Games is owned and supported by PaulSoft, LLC</p> */}
            <p>If you have any questions or concerns, or run into any issues with the games, please send me an email at:</p>
            <p><a href="mailto: paul@paulsoftllc.com" alt="PaulSoft Games Support Email Address">paul@paulsoftgames.com</a></p>
          </Typography>
        </Container>
      </Box>
      <Footer />
    </div>
  )
}

export default Support